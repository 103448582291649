import { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// import pages
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const Reservation = lazy(() => import("./pages/reservation/reservation"));
const NotFound = lazy(() => import("./pages/notfound/notfound"));
const ResturantMenu = lazy(
  () => import("./pages/settings/resturant-menu/resturant-menu")
);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/settings/resturant/menu" element={<ResturantMenu />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/" element={<Navigate to="/settings/resturant/menu" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
