import React from "react";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { jssPreset, StylesProvider } from "@mui/styles";
import { CssBaseline } from "@mui/material";

// import styles
import "../../App.css";

interface ThemeWrapperProps {
  children: React.ReactNode;
}

const ThemeWrapper = (props: ThemeWrapperProps) => {
  let theme = createTheme({
    typography: {
      fontFamily: "Montserrat",
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            maxWidth: "unset",
          },
        },
      },
    },
  });

  // Configure JSS
  const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
  });

  // this line of code make all typography fonts responsive
  theme = responsiveFontSizes(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <CssBaseline />
          {props.children}
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeWrapper;
