import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import components
import ThemeWrapper from "./components/ThemeWrapper/ThemeWrapper";

ReactDOM.render(
  <Suspense fallback="loading ...">
    <ThemeWrapper>
      <App />
    </ThemeWrapper>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
